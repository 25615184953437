<template>
    <div class="row overflow-hidden">
        <div class="col-12" id="sponsorContainer">
            <div class="sponsor-frame d-flex flex-wrap justify-content-center">
                <a href="https://www.knaek.nl/">
                    <img src="@/assets/images/sponsors/knaek-logo.png">
                </a>
                <a href="https://www.utrecht-promotions.nl/">
                    <img src="@/assets/images/sponsors/promotions-sponsor.jpg">
                </a>
                <a href="https://www.kleine-rijopleidingen.nl/">
                    <img src="@/assets/images/sponsors/kleine-rijopleidingen-maar-dan-beter.jpeg">
                </a>
                <a
                    href="http://partnerprogramma.bol.com/click/click?p=1&t=url&s=3033&url=http://www.bol.com/nl/index.html&f=TXL">
                    <img src="@/assets/images/sponsors/bolcom-sponsor.jpg">
                </a>
                <a href="https://www.voetbaltafelwinkel.nl/">
                    <img src="@/assets/images/sponsors/voetbaltafelwinkel.png">
                </a>
                <a href="https://www.dechristelijkezorgverzekeraar.nl/collectiviteit/205504516">
                    <img src="@/assets/images/sponsors/dechristelijkezorgverzekeraar.jpg">
                </a>
            </div>
        </div>
    </div>
</template>